import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { name: String }

  connect() {
    if(window.sessionStorage.getItem("dismissed_" + this.nameValue) != "true") {
        this.element.classList.remove('hidden')
    }
  }

  dismiss() {
    this.element.classList.add('hidden');
    window.sessionStorage.setItem("dismissed_" + this.nameValue, "true");
  }
}
