import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "saveButton"];

  save(event) {
    event.preventDefault();

    this.saveButtonTarget.classList.add('animate-pulse', 'pointer-events-none');

    this.storeValues(this.formValues)
        .then(function() {
          this.saveButtonTarget.classList.remove('animate-pulse', 'pointer-events-none');
          this.showToast();
        }.bind(this));
  }

  async storeValues(values) {
    const token = document.getElementsByName("csrf-token")[0].content;
    const url = '/account/profile';

    var data = new FormData();
    data.append('authenticity_token', token);

    if(Object.keys(values).length == 0) {
      data.append('user[clear_default_package_filter]', 'true');
    } else {
      for (const [key, value] of Object.entries(values)) {
        data.append(`user[default_package_filter][${key}]`, value);
      }
    }

    var req = await fetch(url, {
      method: 'PATCH',
      body: data,
    });

    return req;
  }

  showToast() {
    var toast = document.createElement('div');
    toast.classList.add('toast');
    toast.dataset.controller="toast";
    toast.dataset.action="turbo:visit@window->toast#cleanup";
    toast.innerHTML = "Standaard filter opgeslagen"

    document.getElementById('toasts').appendChild(toast);
  }

  get formValues() {
    let values = {}

    this.inputTargets.forEach((input) => {
      if(input.type == 'radio') {
        if(input.checked) {
          values[input.name] = input.value;
        }
      } else if (input.type == 'checkbox') {
        if(input.checked) {
          values[input.name] = "on";
        }
      } else if (input.name && input.value) {
        values[input.name] = input.value;
      }
    });

    return values;
  }
}
